<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="this.$t('list_applications')" :collapseId="'collapse-2'">
        <template slot="content" slot-scope="{ setLoading }">
          <DataTable :url="url" @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "ApplicationsList",
  components: {
    Ibox,
    DataTable
  },
  data() {
    return {
      excel: true
    };
  },
  computed: {
    url() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/applications`;
    }
  }
};
</script>
